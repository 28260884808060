import cn from 'classnames'

import Slider from 'react-slick'

import Image from 'next/image'

import Link from 'next/link'

import {MouseEventHandler} from 'react'

import Icon from '@/components/atoms/Icon'

import {formatDate} from '@/functions/utils'

import {Post} from '@/components/types/post'

import Container from '@/components/atoms/Container'

import SubtitleWithDot from '@/components/atoms/SubtitleWithDot'

import Video from '@/components/atoms/Video'

import {ImageType} from '@/components/types/image'

import {useWordPressContext} from '@/components/common/WordPressProvider'

import {LinkType} from '@/components/types/link'

import Button from '@/components/atoms/Button'

import {useRouter} from 'next/router'
import {useTranslation} from 'react-i18next'
import styles from './Teaser.module.scss'

type TeaserProps = {
  block: {
    title: string
    subtitle?: string
    subtitle2?: string
    label?: string
    variation?: string
    image?: ImageType
    video?: ImageType
    button?: LinkType
    konto?: boolean
    url?: string
    stickyPosts?: Post[]
    caption?: string
    teaserSubtitle?: string
    hideTeaserButton?: boolean
  }
}

type ArrowProps = {
  className?: string
  onClick?: MouseEventHandler<any>
}

function Arrow(props: ArrowProps) {
  const {className, onClick} = props
  return (
    <div onClick={onClick} className={className}>
      <div className={styles.circle}>
        <Icon icon="arrowRightOutline" size={40} sizeViewbox={40} />
      </div>
    </div>
  )
}

export default function Teaser({block}: TeaserProps) {
  const {
    title,
    subtitle,
    subtitle2,
    label,
    video,
    image,
    button,
    konto,
    variation,
    stickyPosts,
    caption,
    url = '',
    teaserSubtitle,
    hideTeaserButton = false
  } = block

  const {post}: any = useWordPressContext()

  const {push} = useRouter()

  const {featuredImage} = post

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <Arrow />,
    prevArrow: <Arrow />,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  }

  const {t} = useTranslation('account')

  return (
    <div
      className={cn(
        styles.teaser,
        variation === 'extended' ? styles.isExtended : '',
        stickyPosts && stickyPosts?.length > 0 ? styles.isSlider : '',
        caption && caption?.length > 0 ? styles.hasCaption : '',
        konto ? styles.konto : '',
        caption && caption?.length > 0 ? styles.hasCaption : ''
      )}
    >
      <Container removePaddingBottom={konto}>
        {subtitle && subtitle2 && !konto && (
          <SubtitleWithDot
            word1={subtitle}
            word2={subtitle2}
            className={styles.subtitle}
          />
        )}
        <div className={styles.titleSubtitleWrapper}>
          <h1 className={styles.title}>{title}</h1>
          {teaserSubtitle && (
            <div className={styles.teaserSubtitle}>{teaserSubtitle}</div>
          )}
        </div>

        <div className={styles.mediaTextWrapper}>
          {(featuredImage ||
            image ||
            video ||
            (stickyPosts && stickyPosts?.length > 0)) && (
            <div
              className={cn(
                styles.imageVideoContainer,
                stickyPosts && stickyPosts?.length > 0 ? styles.isSlider : ''
              )}
            >
              {stickyPosts && stickyPosts?.length > 0 ? (
                <>
                  <div className={styles.sliderTitle}>Spotlight</div>
                  {/* @ts-ignore */}
                  <Slider {...sliderSettings}>
                    {stickyPosts?.map((post, index) => (
                      <div key={index} className={styles.slide}>
                        {post.featuredImage && (
                          <div
                            className={styles.slideImage}
                            onClick={() => push(`news/${post.slug}`)}
                          >
                            <Image
                              src={post.featuredImage.node.mediaItemUrl}
                              alt={post.featuredImage.node.altText}
                              width={post.featuredImage.node.mediaDetails.width}
                              height={
                                post.featuredImage.node.mediaDetails.height
                              }
                              layout="responsive"
                              objectFit="cover"
                              priority
                            />
                          </div>
                        )}
                        <SubtitleWithDot
                          word1={post.categories.nodes[0].name}
                          word2={formatDate(post.date)}
                          className={styles.slideSubtitle}
                        />
                        <Link href={`news/${post.slug}`}>
                          <a className={styles.slideTitle}> {post.title}</a>
                        </Link>
                      </div>
                    ))}
                  </Slider>
                </>
              ) : video ? (
                <Video video={video} autoplay={post?.__typename === 'Page'} />
              ) : featuredImage && featuredImage.node ? (
                <div className={styles.image}>
                  <Image
                    src={featuredImage.node?.mediaItemUrl}
                    alt={featuredImage.node?.altText}
                    width={featuredImage.node?.mediaDetails.width}
                    height={featuredImage.node?.mediaDetails.height}
                    layout="responsive"
                    priority
                  />
                </div>
              ) : image ? (
                <div className={styles.image}>
                  <Image
                    src={image?.mediaItemUrl}
                    alt={image?.altText}
                    width={image?.mediaDetails.width}
                    height={image?.mediaDetails.height}
                    layout="responsive"
                    priority
                  />
                </div>
              ) : null}
              {caption && caption.length > 0 && (
                <div className={styles.caption}>{caption}</div>
              )}
            </div>
          )}
          {konto ? (
            <div className={styles.label}>
              <SubtitleWithDot
                word1={subtitle}
                word2={subtitle2}
                className={styles.subtitle}
              />
              <Button
                className={styles.moodleButton}
                text={t('moodleButton')}
                icon="arrowOutward"
                iconSize={24}
                type="filled"
                url="#moodle"
                isAnchor
              />
            </div>
          ) : null}
          {(label || button) && (
            <div className={styles.labelButtonWrapper}>
              {label && <div className={styles.label}>{label}</div>}
              {button && !hideTeaserButton && (
                <Button
                  className={cn(
                    styles.button,
                    url.startsWith('#') ? styles.isAnchor : ''
                  )}
                  text={button.title}
                  type={url.startsWith('#') ? 'primary' : 'filled'}
                  iconSize={url.startsWith('#') ? 40 : 24}
                  icon={
                    url.startsWith('#') ? 'arrowRightOutline' : 'buttonArrow'
                  }
                  url={url}
                  isAnchor={url.startsWith('#')}
                  urlExternal={!url.startsWith('#')}
                />
              )}
            </div>
          )}
        </div>
      </Container>
    </div>
  )
}

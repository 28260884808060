import {LinkType} from '@/components/types/link'

import cn from 'classnames'

import {useTranslation} from 'next-i18next'

import {useState} from 'react'

import {Collapse} from 'react-collapse'

import Button from '../../atoms/Button/Button'

import styles from './Accordion.module.scss'

import {formatDate} from '@/functions/utils'

type AccordionProps = {
  title: string
  text?: string
  subtitle?: string
  attributes?: {[key: string]: string}
  bookingNumber?: string
  bookingDate?: string
  status?: string
  language?: string
  link?: LinkType
  isTimeTable?: boolean
  location?: string
}

const Accordion = ({
  title,
  text,
  subtitle,
  attributes,
  bookingNumber,
  bookingDate,
  status,
  link,
  location,
  isTimeTable,
  language
}: AccordionProps) => {
  const [show, setShow] = useState(false)
  // const [ssoHTML, setSsoHTML] = useState('')

  const {t} = useTranslation(['global', 'account', 'labelMapping'])
  let htmlNode
  if (typeof window !== 'undefined') {
    htmlNode = document?.createElement('div')

    if (text) {
      htmlNode.innerHTML = text
    }

    htmlNode?.querySelectorAll('*').forEach(function (node) {
      node.removeAttribute('style')
    })
  }

  // useEffect(() => {
  //   const form = document?.getElementById(
  //     'responseform'
  //   ) as HTMLFormElement | null
  //   if (form) {
  //     form.submit()
  //   }
  // }, [ssoHTML])

  // const openMoodle = async (): Promise<void> => {
  //   try {
  //     const response = await fetch('/api/moodleAuth')
  //     const data = await response.json()

  //     const decodedHtml = he.decode(data.data)
  //     setSsoHTML(decodedHtml)
  //   } catch (error) {
  //     console.error('Error opening moodle:', error)
  //   }
  // }

  return (
    <div className={styles.accordionWrapper}>
      <div
        className={cn(styles.titleWrapper, show ? styles.isOpen : '')}
        onClick={() => setShow(!show)}
      >
        <div className={bookingNumber ? styles.konto : ''}>
          <div className={subtitle ? styles.titleSubtitle : styles.titleOnly}>
            {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
            <div className={bookingNumber ? styles.kontoTitle : styles.title}>
              {title}
            </div>
          </div>
          {attributes?.['pa_dates'] && (
            <div className={styles.dates}>{attributes?.['pa_dates']}</div>
          )}
        </div>
        <div className={styles.icon} />
      </div>
      {/* @ts-ignore */}
      <Collapse isOpened={show}>
        {bookingNumber && (
          <div className={cn(styles.text, styles.details)}>
            <div className={styles.data}>
              <div className={styles.col}>
                <div className={styles.subtitle}>
                  {t('account:bookingLabel')}
                </div>
                <div className={styles.info}>
                  {t('account:orderNumberLabel')}: {bookingNumber} <br />
                  {t('account:orderDate')}:&nbsp;
                  {formatDate(bookingDate)}
                  <br />
                  {status}
                </div>
              </div>

              <div className={cn(styles.col, styles.right)}>
                <div className={styles.subtitle}>
                  {t('account:courseDetailsLabel')}
                </div>
                <div className={styles.info}>
                  {attributes?.['pa_dates'] && (
                    <>
                      {t('account:dateLabel')}: {attributes?.['pa_dates']}
                      <br />
                    </>
                  )}
                  {attributes?.['pa_duration'] && (
                    <>
                      {t('labelMapping:duration')}:{' '}
                      {attributes?.['pa_duration']}
                      <br />
                    </>
                  )}
                  {attributes?.['pa_format'] && (
                    <>
                      {t('labelMapping:format')}: {attributes?.['pa_format']}
                      <br />
                    </>
                  )}
                  {attributes?.['pa_booking-time-slot'] && (
                    <>
                      {t('labelMapping:bookingTimeSlot')}:{' '}
                      {attributes?.['pa_booking-time-slot']}
                      <br />
                    </>
                  )}
                  {location &&
                    location.length > 0 &&
                    location[0]?.length > 0 && (
                      <>
                        {t('labelMapping:location')}: {location}
                        <br />
                      </>
                    )}
                  {language &&
                    language.length > 0 &&
                    language[0]?.length > 0 && (
                      <>
                        {t('labelMapping:language')}: {language}
                      </>
                    )}
                </div>
              </div>
            </div>

            {/* <div className={styles.moodle}>
              {ssoHTML && <div dangerouslySetInnerHTML={{__html: ssoHTML}} />}
              <Button
                tag="button"
                text={t('account:coursePortalLabel')}
                type="filled"
                icon={'arrowOutward'}
                iconSize={24}
                onClick={() => openMoodle()}
              />
            </div> */}
          </div>
        )}

        {htmlNode?.innerHTML && (
          <div
            className={cn(styles.text, isTimeTable ? styles.isTimeTable : '')}
          >
            <div dangerouslySetInnerHTML={{__html: htmlNode?.innerHTML}} />
            {link && <Button type="primary" url={link.url} text={link.title} />}
          </div>
        )}
      </Collapse>
    </div>
  )
}

export default Accordion
